import { memo, useRef, useState } from 'react'

import './styles.scss'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { FaqIcon } from 'common/icon'

const FaqItem = ({
  question,
  answer,
}: {
  question: string
  answer: string
}) => {
  console.log('re-render')
  const [watchFaq, setWatchFaq] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <div className="faq-item w-[80%] bg-[#F1F9FE] flex flex-row items-center justify-between rounded-[50px] py-[20px] md:py-[20px] px-[30px] md:px-[40px] mb-[25px]">
      <div className="faq-text">
        <p
          className={`question text-[18px] sm:text-[20px] md:text-[22px] lg:text-[24px] text-[${watchFaq ? '#34A853' : '#000000'}]`}
        >
          {question}
        </p>
        <div
          ref={contentRef}
          className={`answer-wrapper ${watchFaq ? 'expanded' : 'collapsed'}`}
          style={{ maxHeight: watchFaq ? contentRef.current?.scrollHeight : 0 }}
        >
          <p className="answer text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px]">
            {answer}
          </p>
        </div>
      </div>
      <div>
        <PlusOutlined
          className={`${watchFaq ? 'hidden' : 'block'} text-[#F9B800] text-[20px] sm:text-[28px] lg:text-[36px]`}
          onClick={() => setWatchFaq(!watchFaq)}
        />
        <MinusOutlined
          className={`${watchFaq ? 'block' : 'hidden'} text-[#F9B800] text-[20px] sm:text-[28px] lg:text-[36px]`}
          onClick={() => setWatchFaq(!watchFaq)}
        />
      </div>
    </div>
  )
}

export default memo(FaqItem)
