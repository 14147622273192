import { memo } from 'react'

import './styles.scss'
import { FacebookIcon, GDSCIcon, InstaIcon, LinkedInIcon } from 'common/icon'
import { FOOTER_CONTACT } from './const'

const Footer = () => {
  return (
    <div className="footer overflow-hidden">
      <div className="footer-bg-end relative bottom-[-10px] sm:bottom-[-20px] lg:bottom-[-30px] xl:bottom-[-50px] z-[1] flex items-center justify-center overflow-hidden">
        <img
          className="z-1 pt-[20px] w-[100%]"
          src="static/imgs/wave.svg"
          alt="opps"
        />
        <img
          className="footer-bg-back2 z-2 absolute right-[-10px] md:right-[-50px] bottom-[30px] rotate-[-60deg] w-[60px] sm:w-[80px] md:w-[150px] xl:w-[220px]"
          src="static/imgs/intro/smallTree.svg"
          alt="opps"
        />
        <img
          className="footer-bg-back1 z-2 absolute left-[0] sm:left-[-20px] bottom-[10px] rotate-90 w-[50px] sm:w-[80px] md:w-[120px] xl:w-[170px]"
          src="static/imgs/intro/bigTree.svg"
          alt="opss"
        />
        <img
          className="z-2 absolute right-[20%] top-[10px]"
          src="static/imgs/bong.svg"
          alt="opps"
        />
        <img
          className="z-2 absolute top-[10px] left-[20%] w-[70px] sm:w-[100px] md:w-[120px] lg:w-[150px] xl:w-[200px]"
          src="static/imgs/logo.svg"
          alt="opps"
        />
      </div>
      <div className="footer-main relative w-[100%] bg-[#498AF4] z-[9]">
        <div className="footer-body py-[30px] px-[10px] sm:px-[10px] md:px-[30px] lg:px-[50px] xl:px-[90px]">
          <div className="footer-title flex flex-row items-start gap-[20px]  pb-[10px] mb-[10px] md:border-b-[2px] md:border-b-[#fff]">
            <GDSCIcon width={50} height={50} color="#fff" />
            <div className="flex flex-col items-center md:block">
              <p className="text-[18px] sm:text-[18px] md:text-[24px] lg:text-[26px]">
                Google Developers Student Clubs
              </p>
              <p className="text-[13px] md:text-[16px] lg:text-[18px]">
                Posts and Telecommunications Institute of Technology
              </p>
            </div>
          </div>
          <div className="footer-link flex flex-col md:flex-row items-center gap-[20px] mt-[20px] md:mt-[0px]">
            <div className="flex flex-row gap-[20px] items-center ">
              <a
                href="https://www.facebook.com/gdsc.ptit"
                className="cursor-pointer"
              >
                <FacebookIcon width={28} height={28} color="#fff" />
              </a>
              <a
                href="https://www.instagram.com/gdsc.ptit/"
                className="cursor-pointer"
              >
                <InstaIcon width={28} height={28} color="#fff" />
              </a>
              <a className="cursor-pointer">
                <LinkedInIcon width={36} height={36} color="#fff" />
              </a>
            </div>
            <div className="flex flex-col items-center md:block">
              <p className="text-[14px] md:text-[15px]">
                Copyright Google Developer Student Club - PTIT.
              </p>
              <p className="text-[14px] md:text-[15px]">All right reserved.</p>
            </div>
          </div>
          <div className="footer-contact pb-[20px] md:pb-0 md:ml-[50px] flex flex-col items-center md:items-start justify-center gap-[20px] border-b-[2px] border-b-[#fff] md:border-none">
            {FOOTER_CONTACT?.map((item: any, index: number) => (
              <div
                key={index}
                className="flex flex-row items-center gap-[10px] text-[15px] sm:text-[18px] lg:text-[20px]"
              >
                {item?.icon}
                <div className="flex flex-row justify-start gap-[10px]">
                  <p>{item?.title}</p>
                  <a
                    href={
                      item?.typeHref === 'link'
                        ? item?.href
                        : `${item?.typeHref}${item?.value}`
                    }
                  >
                    {item?.value}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(Footer)
