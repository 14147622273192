import Footer from '../footer'
import Navbar from 'modules/home/layout/NavBar'
import { Button, Drawer } from 'antd'
import { memo } from 'react'
import './styles.scss'
import { ArrowRightOutlined } from '@ant-design/icons'
import { ROUTES } from '../../routes/const'

const Not_Found = () => {
  return (
    <div>
      <Navbar />
      <div className="background_1 h-[400px]">
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="bong bong"
          className="bubbles_1 hidden absolute h-[30px] w-[30px] left-[317.57px] top-[165.5px] lg:flex"
        />
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="bong bong"
          className="bubbles_2 hidden absolute h-[30px] w-[30px] left-[148.24px] top-[374.62px] lg:flex"
        />
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="bong bong"
          className="bubbles_3 hidden absolute h-[30px] w-[30px] left-[60%] top-[150px] lg:flex"
        />
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="bong bong"
          className="bubbles_4 hidden absolute h-[30px] w-[30px] left-[85%] top-[156px] lg:flex"
        />
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="bong bong"
          className="bubbles_5 hidden absolute h-[38px] w-[38px] left-[44%] top-[365px] z-[1] lg:flex"
        />
        <img
          src="/static/imgs/logo.svg"
          alt="logo"
          className="logo hidden absolute w-[108.39px] h-[70.07px] left-[353.11px] top-[334px] lg:flex"
        />
        <img
          src="/static/imgs/castle.svg"
          alt="castle"
          className="castle w-[198px] h-[253px]"
        />
        <img
          src="/static/imgs/slider-back3.svg"
          alt="slider"
          className="slider_back3 hidden absolute top-[280px] left-[43%] w-[54px] h-[120px] z-[-1] lg:flex"
        />
        <img
          src="/static/imgs/green_fish.svg"
          alt="green_fish"
          className="green_fish hidden absolute h-[74px] top-[201px] left-[70%] lg:flex"
        />
        <img
          src="/static/imgs/fish1.svg"
          alt="fish"
          className="fish1 hidden absolute w-[106px] h-[74px] top-[298px] left-[80%] lg:flex"
        />
        <img
          src="/static/imgs/slider-back4.svg"
          alt="slider"
          className="slider_back4 hidden absolute h-[120px] w-[120px] top-[270px] left-[50%] z-[-1] lg:flex"
        />
      </div>
      <div className="contents">
        <h1 className="title text-5xl">Page Not Found :(</h1>
        <p className="body-text text-3xl">
          Oops! Nội dung bạn tìm kiếm có thể vừa bị mất tích.
        </p>
        <div className="buttons flex-col flex md:flex-row">
          <Button
            type="primary"
            size="large"
            href={ROUTES.HOME}
            className="btn_return m-[10px] md:mr-[10px]"
          >
            Quay lại trang chủ
          </Button>
          <Button
            icon={<ArrowRightOutlined size={30} color="#498AF4" />}
            iconPosition="end"
            type="primary"
            size="large"
            href={'https://forms.gle/Lq6GDqcJc3sEweoy6'}
            className="btn_google_form btn_google_form:hover m-[10px] md:ml-[10px] "
          >
            Đăng kí qua Google Form
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default memo(Not_Found)
