import { DomainIcon, MailIcon, PhoneIcon } from 'common/icon'

export const FOOTER_CONTACT = [
  {
    icon: <PhoneIcon width={24} height={24} color="#fff" />,
    title: 'Số điện thoại:',
    value: '0904112882 (MR.Minh)',
    typeHref: 'tel:',
  },
  {
    icon: <MailIcon width={24} height={24} color="#fff" />,
    title: 'Email:',
    value: 'contact@gdscptit.dev',
    typeHref: 'mailto:',
  },
  {
    icon: <DomainIcon width={24} height={24} color="#fff" />,
    title: 'Domain:',
    value: 'gdscptit.dev', // Display text without "https://"
    href: 'https://gdscptit.dev/', // Full URL for the link
    typeHref: 'link',
  },
]
