import { memo, useEffect, useRef, useState } from 'react'
import ItemLayout from './component/ItemLayout'
import './styles.scss'
import useOnScreen from 'hooks/useOnScreen'

const data = [
  {
    title: 'Learn',
    content:
      'Khám phá những chân trời kiến thức mới, mở rộng tầm nhìn và tư duy.',
    colorBg: '#E6F5FE',
    colorBorder: '#A6D5FA',
    colorText: '#4285F4',
  },
  {
    title: 'Share',
    content:
      'Mọi ý kiến đóng góp đều được lắng nghe và tôn trọng, tạo nên một không gian làm việc cởi mở và sáng tạo.',
    colorBg: '#FFF6F7',
    colorBorder: '#E57373',
    colorText: '#E57373',
  },
  {
    title: 'Connect',
    content:
      'Mở rộng mạng lưới quan hệ, kết nối với những người cùng chung đam mê và cùng nhau tạo ra những giá trị mới.',
    colorBg: '#E8F5E9',
    colorBorder: '#81C784',
    colorText: '#34A853',
  },
  {
    title: 'Grow',
    content:
      'Cùng nhau vượt qua giới hạn bản thân, khám phá những tiềm năng mới và trở thành phiên bản tốt nhất của chính mình.',
    colorBg: '#FFF8E1',
    colorBorder: '#FFD54F',
    colorText: '#F9AB00',
  },
]

const Layout1 = () => {
  const layoutRef = useRef<HTMLDivElement | null>(null)
  const isVisible = useOnScreen(layoutRef)

  return (
    <div className="w-[100%] relative flex flex-col items-center justify-center">
      <div className="aboutus__layout1 px-[0] xl:px-[80px] pt-[30px] max-w-[335px] sm:max-w-[100%]">
        <div className="flex flex-col sm:grid sm:grid-cols-7 sm:grid-rows-1 gap-[20px] sm:gap-[40px] md:gap-[50px] lg:gap-[80px] py-[20px] px-[20px] sm:py-[30px] sm:px-[49px] md:py-[40px] md:px-[50px] lg:py-[50px] lg:px-[70px] bg-[#E6F5FE] rounded-[50px] border-solid border-[3px] border-[#A6D5FA] shadow-[4px_5px_10px_0px_#B4D0FFB2]">
          <div className="aboutus__layout1-intro row-span-1 sm:col-span-3">
            <p>Về GDSC:</p>
            <p>
              Google Developer Student Clubs (GDSC viết tắt) là chương trình
              được Google cấp quyền tổ chức và hỗ trợ, dành cho sinh viên có
              niềm yêu thích CNTT nói chung và công nghệ Google nói riêng. Năm
              2023, đã có hơn 1500 GDSC Lead tại hơn 100 quốc gia.
            </p>
          </div>
          <div className="aboutus__layout1-intro row-span-1 sm:col-span-4">
            <p>Về GDSC-PTIT:</p>
            <p>
              Tháng 9/2022, GDSC-PTIT chính thức ra đời, là một chapter của GDSC
              tại Học viện Công nghệ Bưu chính Viễn thông. Đây là môi trường bổ
              ích để các thành viên có thể học tập và phát triển bản thân từ
              nguồn tài nguyên của Google, cùng nhau thực hiện sứ mệnh đem đến
              những giải pháp mang lại thay đổi tích cực cho địa phương và cộng
              đồng.
            </p>
          </div>
        </div>
        <div
          ref={layoutRef}
          className="w-[100%] grid grid-rows-4 justify-items-center gap-y-[20px] sm:grid-rows-2 sm:grid-cols-2 sm:gap-x-[20px] xl:gap-x-[40px] xl:grid-cols-4 xl:grid-rows-1 mt-[50px]"
        >
          {data.map((item, index) => (
            <ItemLayout
              title={item.title}
              content={item.content}
              colorBg={item.colorBg}
              colorBorder={item.colorBorder}
              colorText={item.colorText}
              key={index}
              className={`item-appear ${isVisible ? 'visible' : ''}`}
            />
          ))}
        </div>
      </div>
      <img
        src="/static/imgs/wave.svg"
        alt="opps"
        className="aboutus__layout1-wave"
      />
      <img
        src="/static/imgs/duongKe.svg"
        alt="opps"
        className="aboutus__layout1-wave"
      />
    </div>
  )
}

export default memo(Layout1)
