import { memo, useEffect, useState } from 'react'
import { scroller } from 'react-scroll'

import './styles.scss'
import FaqItem from './components/FaqItem'
import { Button } from 'antd'
import { FaqIcon } from 'common/icon'

const dataFaq = [
  {
    question: 'Ai có thể tham gia GDSC - PTIT?',
    answer:
      'Tất cả sinh viên từ PTIT và các trường đại học khác đều có thể tham gia GDSC - PTIT, không phân biệt ngành học hay năm học. Câu lạc bộ chào đón tất cả những ai có đam mê về công nghệ, muốn học hỏi và phát triển bản thân.',
  },
  {
    question:
      'GDSC - PTIT có yêu cầu gì về trình độ lập trình trước khi tham gia?',
    answer:
      'Không có yêu cầu cụ thể về trình độ lập trình. Bạn không cần phải biết lập trình để tham gia GDSC - PTIT. Chúng tôi có các ban không liên quan đến kỹ thuật (non-tech) như HR - LG, Media, PR, Design. Ngay cả khi bạn học kinh tế hay các ngành khác, chỉ cần bạn đam mê làm việc trong môi trường công nghệ và muốn đóng góp vào các dự án của câu lạc bộ, chúng tôi đều hoan nghênh bạn.',
  },
  {
    question: 'Thành viên GDSC - PTIT sẽ tham gia vào những hoạt động nào?',
    answer:
      'Thành viên sẽ được tham gia vào các buổi workshop, seminar, hackathon, làm việc nhóm trong các dự án cộng đồng, cũng như các buổi networking với các chuyên gia trong ngành. Đối với các bạn tham gia ở các ban non-tech, các hoạt động có thể bao gồm tổ chức sự kiện, quản lý nhân sự - hậu cần, truyền thông, thiết kế đồ họa, và nhiều hơn nữa.',
  },
  {
    question:
      'Mình có thể tham gia GDSC - PTIT cùng lúc với việc tham gia các câu lạc bộ khác không?',
    answer:
      'Có, bạn hoàn toàn có thể tham gia GDSC - PTIT song song với các câu lạc bộ khác tại trường hoặc trường khác, miễn là bạn có thể cân bằng thời gian và hoàn thành các nhiệm vụ của mình.',
  },
  {
    question:
      'Nếu mình không phải là sinh viên của PTIT, mình có thể tham gia GDSC - PTIT không?',
    answer:
      'Có, GDSC - PTIT luôn chào đón tất cả các bạn sinh viên, dù bạn học ở bất kỳ trường nào. Chúng tôi khuyến khích sự đa dạng và tin rằng sự đóng góp của bạn sẽ mang lại giá trị lớn cho cộng đồng của chúng tôi.',
  },
]

const FAQ = () => {
  const [faqItem, setFaqItem] = useState<any>([])
  const [collapseFlag, setCollapseFlag] = useState(true)

  useEffect(() => {
    setFaqItem(collapseFlag ? dataFaq.slice(0, 4) : dataFaq)
  }, [collapseFlag])
  return (
    <div className="faq w-[100%] flex flex-col items-center pt-[30px]">
      <img
        className="hidden lg:block absolute top-[0px] right-[15%] w-[60px] md:w-[80px] lg:w-[100px] xl:w-[110px] rotate-[-15deg]"
        src="static/imgs/faq/caBlue.svg"
        alt="opps"
      />
      <img
        className="hidden lg:block absolute top-[10px] left-[20%]"
        src="static/imgs/bong.svg"
        alt="opps"
      />
      <div className="faq-title flex flex-row gap-[20px] items-center mb-[30px]">
        <FaqIcon />
        <h3 className="text-[#EB4A3D] text-[24px] sm:text-[28px] md:text-[36px] lg:text-[40px]">
          Câu hỏi thường gặp
        </h3>
      </div>
      {faqItem?.map((item: any, index: number) => (
        <FaqItem
          key={index}
          question={item?.question as string}
          answer={item?.answer as string}
        />
      ))}
      <Button
        className="faq-btn w-[100px] py-[15px] px-[5px] md:py-[25px] md:px-[15px] !text-[16px] lg:w-[150px] !lg:text-[20px] mb-[30px]"
        onClick={() => {
          setCollapseFlag(!collapseFlag)
          scroller.scrollTo('faq', {
            duration: 2000,
            spy: true,
            smooth: true,
            offset: -80,
          })
        }}
      >
        {collapseFlag ? 'Thêm' : 'Thu gọn'}
      </Button>
    </div>
  )
}
export default memo(FAQ)
