import { Button, Drawer } from 'antd'
import { memo, useEffect, useState } from 'react'
import { scroller, Link as ScrollLink } from 'react-scroll'
import { ArrowRightOutlined } from '@ant-design/icons'

import './styles.scss'
import { ROUTES } from 'routes/const'
import { MenuIcon } from 'common/icon'
import { useNavigate } from 'react-router'

const NAV_DATA = [
  {
    name: 'Trang chủ',
    url: 'intro',
  },
  {
    name: 'Về chúng tôi',
    url: 'about_us',
  },
  {
    name: 'Thông tin tuyển thành viên',
    url: 'member_register',
  },
  {
    name: 'Câu hỏi thường gặp',
    url: 'faq',
  },
]

const NavBar = () => {
  const [open, setOpen] = useState(false)
  const [activeLink, setActiveLink] = useState(NAV_DATA[0].url)
  const navigate = useNavigate()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <nav className="navbar m-auto xl:max-w-[1540px] fixed top-0 right-0 left-0 h-[80px]  border-b-[1px] border-solid border-[#90CAF9] bg-white z-[1000]">
      <div className="relative flex sm:flex-row md:justify-between justify-center items-center h-[100%] mx-[10px] sm:mx-[20px] xl:mx-[50px]">
        <div
          onClick={showDrawer}
          className="absolute right-0 text-center md:static block sm:w-[180px] lg:hidden"
        >
          <div className="mr-3">
            <MenuIcon width={24} height={24} color="#717171" />
          </div>
        </div>
        <div
          onClick={() => {
            navigate(ROUTES.HOME)
          }}
        >
          <img
            src="/static/imgs/GDSC-PTIT-02@3x 1.svg"
            className="cursor-pointer"
          />
        </div>
        {NAV_DATA.map((item: any) => (
          <ScrollLink
            key={item.url}
            to={item.url}
            spy={true}
            duration={500}
            smooth={true}
            offset={-79}
            className={`hidden lg:block ${
              activeLink === item.url ? 'active' : ''
            }`}
            onSetActive={() => setActiveLink(item.url)}
          >
            <p className="cursor-pointer">{item.name}</p>
          </ScrollLink>
        ))}
        <Button
          icon={<ArrowRightOutlined size={30} color="#498AF4" />}
          iconPosition="end"
          type="primary"
          size="large"
          href={ROUTES.MEMBER_REG}
          className="nav-btn cursor-pointer hidden md:inline-flex hover:opacity-[0.7]"
        >
          Đăng kí vào CLB
        </Button>
      </div>

      <Drawer
        onClose={onClose}
        open={open}
        placement="left"
        width={200}
        className="nav-drawer md:!w-[300px]"
      >
        {NAV_DATA.map((item: any) => (
          <ScrollLink
            key={item.url}
            to={item.url}
            spy={true}
            smooth={true}
            offset={-79}
            onSetActive={() => setActiveLink(item.url)}
            onClick={() => {
              setOpen(false)
            }}
          >
            <p
              className={`nav-draw-text cursor-pointer ${
                activeLink === item.url ? 'active' : ''
              }`}
            >
              {item.name}
            </p>
          </ScrollLink>
        ))}
      </Drawer>
    </nav>
  )
}
export default memo(NavBar)
