export const SLIDER_IMG = [
  {
    title: 'Sản phẩm và dự thi',
    data: [
      'static/imgs/aboutme2/slider1/1.jpg',
      'static/imgs/aboutme2/slider1/2.jpg',
      'static/imgs/aboutme2/slider1/5.jpg',
      'static/imgs/aboutme2/slider1/6.jpg',
      'static/imgs/aboutme2/slider1/7.jpg',
      'static/imgs/aboutme2/slider1/8.jpg',
      'static/imgs/aboutme2/slider1/9.jpg',
      'static/imgs/aboutme2/slider1/10.jpg',
    ],
  },
  {
    title: 'Tổ chức sự kiện',
    data: [
      'static/imgs/aboutme2/slider2/1.jpg',
      'static/imgs/aboutme2/slider2/2.jpg',
      'static/imgs/aboutme2/slider2/3.jpg',
      'static/imgs/aboutme2/slider2/4.jpg',
      'static/imgs/aboutme2/slider2/5.jpg',
      'static/imgs/aboutme2/slider2/6.jpg',
      'static/imgs/aboutme2/slider2/7.jpg',
    ],
  },
  {
    title: 'Đào tạo nội bộ',
    data: [
      'static/imgs/aboutme2/slider3/1.jpg',
      'static/imgs/aboutme2/slider3/2.jpg',
      'static/imgs/aboutme2/slider3/3.jpg',
    ],
  },
  {
    title: 'Hoạt động gắn kết',
    data: [
      'static/imgs/aboutme2/slider4/1.jpg',
      'static/imgs/aboutme2/slider4/2.jpg',
      'static/imgs/aboutme2/slider4/3.jpg',
    ],
  },
]
