import { BongIcon } from 'common/icon'
import { memo } from 'react'

const ItemLayout = ({
  title,
  content,
  colorBorder,
  colorText,
  colorBg,
  className,
}: {
  title: string
  content: string
  colorBorder: string
  colorText: string
  colorBg: string
  className: string
}) => {
  return (
    <div
      className={`${className} w-[100%] flex flex-col items-center max-w-[335px] rounded-[50px] border-solid border-[3px] py-[16px] px-[20px]`}
      style={{
        backgroundColor: colorBg,
        borderColor: colorBorder,
      }}
    >
      <BongIcon
        width={50}
        height={50}
        color="#fff"
        className="item-appear-bong1"
      />
      <BongIcon
        width={30}
        height={30}
        color="#fff"
        className="item-appear-bong2"
      />
      <p
        style={{ color: colorText }}
        className="item-appear-title font-bold text-center mb-[10px]"
      >
        {title}
      </p>
      <p className="item-appear-content text-center">{content}</p>
    </div>
  )
}

export default memo(ItemLayout)
