import Footer from 'modules/footer'
import { memo } from 'react'
import Navbar from './layout/NavBar'
import { Element } from 'react-scroll'
import Intro from './layout/Intro'
// import AboutUs from './layout/Layout2'
import MemberReg from './layout/MemberReg'
import Faq from './layout/Faq'
import Layout2 from './layout/AboutUs/Layout2'
import Layout1 from './layout/AboutUs/Layout1'
import AboutUs from './layout/AboutUs'
// import AboutUs from './layout/AboutUs'

const Home = () => {
  return (
    <div className="relative m-auto xl:max-w-[1540px]">
      <Navbar />
      <main className="pt-[80px] px-[10px] sm:px-[10px] md:px-[30px] lg:px-[30px] xl:px-[10px]">
        <Element name="intro">
          <Intro />
        </Element>
        <Element name="about_us">
          <AboutUs />
        </Element>
        <Element name="member_register">
          <MemberReg />
        </Element>
        <Element name="faq">
          <Faq />
        </Element>
      </main>
      <Footer />
    </div>
  )
}
export default memo(Home)
