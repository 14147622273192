import { notification } from 'antd'
import { TOAST_TIME } from 'common/const'
type ToastProps = {
  message: string
  description: string
  onClose?: () => void
  className?: string
  icon?: JSX.Element
}

const Toast = ({ message, description, onClose }: ToastProps) => {
  notification.open({
    message: message,
    description: <div className="text-base font-medium">{description}</div>,
    placement: 'topRight',
    onClose: onClose,
    duration: TOAST_TIME,
    className: 'border-l-4 border-[#45CF28] rounded-l-lg',
  })
}

const SuccessToast = ({
  message,
  description,
  onClose,
  className,
  icon,
}: ToastProps) => {
  notification.success({
    message: message,
    description: <div className="text-base font-medium">{description}</div>,
    placement: 'topRight',
    onClose: onClose,
    duration: TOAST_TIME,
    className: 'border-l-4 border-[#243c5a] rounded-l-lg',
  })
}

const ErrorToast = ({
  message,
  description,
  onClose,
  icon = <></>,
}: ToastProps) => {
  notification.error({
    message: message,
    description: <div className="text-base font-medium">{description}</div>,
    placement: 'topRight',
    onClose: onClose,
    duration: TOAST_TIME,
    icon: icon,
    className: 'border-l-4 border-[#FF0000] rounded-l-lg',
  })
}

export { ErrorToast, SuccessToast, Toast }
