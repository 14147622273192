import { ROUTES } from 'routes/const'
import './styles.scss'
import { useEffect, useState } from 'react'
import { Modal } from 'antd'

interface RoleDescription {
  title: string
  description: string
  roleType: 'tech' | 'non-tech'
}

export const Layout1 = () => {
  const techDescription: RoleDescription[] = [
    {
      roleType: 'tech',
      title: 'Cloud',
      description:
        '• Được đào tạo các kiến thức về sử dụng các nền tảng cloud như Google Cloud Platform, AWS, các kiến thức về cơ sở hạ tầng mạng, hệ điều hành linux…<br/><br/>• Đối với Team Tech Cloud, đầu ra mong muốn giúp các bạn có nghiệp vụ để triển khai các ứng dụng trên cloud, thành thạo sử dụng linux… deploy, các web application hoặc model AI trên nền tảng điện toán đám mây.',
    },
    {
      roleType: 'tech',
      title: 'Desktop',
      description:
        '• Được đào tạo các kiến thức về Desktop Application sử dụng C++ Qt6 hoặc C# .NET (Winforms, WPF).<br/><br/>• Đối với Team Tech Desktop, đầu ra mong muốn giúp các bạn có nghiệp vụ để triển khai một ứng dụng trên Windows, Linux hoặc MacOS.',
    },
    {
      roleType: 'tech',
      title: 'Web',
      description:
        '• Được đào tạo các kiến thức về web, với định hướng Frontend sẽ được đào tạo về UI/UX, ReactJS, TailwindCSS, Ant Design…<br/><br/>• Được đào tạo các kiến thức về web, với định hướng Backend sẽ được đào tạo về triển khai API cho một web application, sử dụng Python Django, Ruby on Rails, …<br/><br/>• Đối với Team Tech Web, đầu ra mong muốn sẽ giúp các bạn có nghiệp vụ để triển khai một trang web hoàn chỉnh.',
    },
  ]
  const nontechDescription: RoleDescription[] = [
    {
      roleType: 'non-tech',
      title: 'Truyền thông',
      description:
        'Truyền thông là vị trí tận dụng khả năng sáng tạo vào việc sản xuất nội dung trên các phương tiện truyền thông để thu hút khán giả. Phối hợp với các thành viên và phòng ban khác để lên nội dung  và kế hoạch truyền thông. Quản lý, kiểm duyệt và điều chỉnh nội dung trên các kênh truyền thông  của GDSC-PTIT.',
    },
    {
      roleType: 'non-tech',
      title: 'Design',
      description:
        'Designer là vị trí tạo ra sản phẩm thiết kế dựa trên branding Google. Vị trí này sẽ là người  định hình lên những giá trị hình ảnh trên mạng xã hội, ấn phẩm trong sự kiện từ những ý tưởng  được tạo ra bởi GDSC-PTIT.',
    },
    {
      roleType: 'non-tech',
      title: 'Nhân sự - Hậu cần',
      description:
        'GDSC-PTIT đang tìm kiếm người bạn đồng hành ưa thích làm việc với những mối quan hệ.  Nói cách khác, bạn sẽ là người theo dõi và điều hành các hoạt động nội bộ, dung hòa các mối  quan hệ nội bộ, hỗ trợ các team khác trong công tác hậu cần để quá trình hoạt động của GDSC PTIT được tiến hành đúng theo mục tiêu.',
    },
  ]
  const applicationRounds: ApplicationRounds[] = [
    { name: 'đơn', startAt: '2024-09-06', endAt: '2024-09-18' },
    { name: 'phỏng vấn', startAt: '2024-09-21', endAt: '2024-09-22' },
    { name: 'thử việc', startAt: '2024-09-29', endAt: '2024-12-30' },
  ]

  return (
    <div className="w-full space-y-5 md:space-y-7 mt-28 mb-6 px-auto">
      {/* Tuyển thành viên title section */}
      <div className="relative-wrap">
        <div className="title-container">
          <span style={{ color: '#4285f4' }}>Tuyển thành viên </span>
          <div className="starfish-container">
            <img src="/static/imgs/starfish-yellow.svg" className="starfish" />
            <span style={{ color: '#eb4a3d' }}>Gen 3</span>
          </div>
        </div>
        <img src="/static/imgs/memReg/bong.svg" className="bubble" />
        <img src="/static/imgs/memReg/caVang.svg" className="fish" />
      </div>

      {/* Roles split into tech & non-tech, has an icon and label each */}
      <div className="tag-group-container space-y-5">
        <TagGroup
          tags={techDescription.map((item) => item.title)}
          position="tech"
        />
        <TagGroup
          tags={nontechDescription.map((item) => item.title)}
          position="non-tech"
        />
      </div>

      {/* Application rounds with deadline each */}
      <ApplicationRoundGroup rounds={applicationRounds} />

      {/* A count down component counting to cv application round */}
      <CountDownGroup applicationEndAt={applicationRounds[0].endAt} />

      {/* Register button section that route to form input page */}
      <div className="register-button-group">
        <a className="register-button" href={ROUTES.MEMBER_REG}>
          Đăng kí ngay
        </a>
        <img src="/static/imgs/memReg/caXanh.svg" className="fish" />
        <img src="/static/imgs/memReg/bong.svg" className="bubble" />
      </div>

      {/* Overview section with benefits and role description */}
      <OverviewComponent
        techDescription={techDescription}
        nontechDescription={nontechDescription}
      />
    </div>
  )
}

const OverviewComponent: React.FC<OverviewProps> = ({
  techDescription,
  nontechDescription,
}) => {
  const benefits = [
    'Gia tăng thương hiệu cá nhân',
    'Được truy cập vào nguồn tài nguyên Google for Developers',
    'Nhận quà tặng từ Google và Google for Developers',
    'Mở rộng mối quan hệ',
    'Phát triển đều về kỹ năng chuyên môn và kỹ năng mềm',
    'Tiếp cận các cơ hội học tập, làm việc, các cuộc thi, sự kiện liên quan',
    'Môi trường làm việc chuyên nghiệp, năng động, hòa đồng',
    'Nhận certificate',
  ]
  const BenefitOverview = () => {
    return (
      <div className="benefit-container">
        <p className="title">Quyền lợi thành viên</p>
        <div className="list-container space-y-2">
          {benefits.map((item, _) => (
            <div className="list-item space-x-2">
              <img src="/static/imgs/memReg/subtract.svg" className="icon" />
              <span className="text">{item}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const RoleOverview: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedTag, setSelectedTag] = useState<RoleDescription>({
      title: '',
      description: '',
      roleType: 'tech',
    })

    const showModal = (role: RoleDescription) => {
      setSelectedTag(role)
      setIsModalVisible(true)
    }

    const handleCancel = () => {
      setIsModalVisible(false)
    }

    return (
      <div className="role-container space-y-2">
        <span className="title">Mô tả công việc</span>
        {/* Tech tag group */}
        <div className="tag-group">
          <span className="tech-head-tag">Tech</span>
          {techDescription.map((role, _) => (
            <>
              <button className="tech-item-tag" onClick={() => showModal(role)}>
                {role.title}
              </button>
            </>
          ))}
        </div>
        {/* Non-tech tag group */}
        <div className="tag-group">
          <span className="non-tech-head-tag">Non - Tech</span>
          {nontechDescription.map((role, _) => (
            <button
              className="non-tech-item-tag"
              onClick={() => showModal(role)}
            >
              {role.title}
            </button>
          ))}
        </div>

        <Modal
          title={
            <div
              className={`modal-header ${selectedTag.roleType === 'tech' ? 'tech' : 'nontech'}`}
            >
              {selectedTag.title}
            </div>
          }
          open={isModalVisible}
          onCancel={handleCancel}
          closeIcon={null}
          footer={null}
          centered
        >
          <p dangerouslySetInnerHTML={{ __html: selectedTag.description }}></p>
        </Modal>
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center">
      <div className="overview-container md:gap-2 sm:space-x-4">
        <BenefitOverview />
        <RoleOverview />
        <img src="/static/imgs/memReg/bong.svg" className="bubble" />
      </div>
    </div>
  )
}

interface OverviewProps {
  techDescription: RoleDescription[]
  nontechDescription: RoleDescription[]
}

interface CountdownGroupProps {
  applicationEndAt: string
}

const CountDownGroup: React.FC<CountdownGroupProps> = ({
  applicationEndAt,
}) => {
  const calculateTimeLeft = () => {
    const endDate = new Date(applicationEndAt)
    endDate.setHours(23, 59, 59, 999) // Set endDate to 23:59:59.999

    const now = new Date().getTime()
    const difference = endDate.getTime() - now

    let timeLeft = {
      day: '00',
      hour: '00',
      minute: '00',
      second: '00',
    }

    if (difference > 0) {
      timeLeft = {
        day: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          '0',
        ),
        hour: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
          2,
          '0',
        ),
        minute: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          '0',
        ),
        second: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [applicationEndAt])

  interface CountdownComponentProps {
    label: string
    value: string
  }

  const CountDownComponent: React.FC<CountdownComponentProps> = ({
    label,
    value,
  }) => {
    return (
      <div className="flex flex-col justify-center items-center">
        <span className="countdown-date-label">{label}</span>
        <div className="countdown-number-container">
          <div className="bubble-wrap">
            <img src="/static/imgs/memReg/doBong1.svg" className="bubble" />
          </div>
          <span className="countdown-number">{value}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-evenly items-center space-y-1 md:space-x-9 countdown-group">
      <CountDownComponent label="Ngày" value={timeLeft.day} />
      <CountDownComponent label="Giờ" value={timeLeft.hour} />
      <CountDownComponent label="Phút" value={timeLeft.minute} />
      <CountDownComponent label="Giây" value={timeLeft.second} />
    </div>
  )
}

// Move to utils later
function convertDateFormat(inputDate: string) {
  // Create a Date object from the input date string
  const dateObj = new Date(inputDate)

  // Get the day and month, ensuring they are two digits (pad with leading zeros if necessary)
  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')

  // Return the formatted date as "dd/MM"
  return `${day}/${month}`
}

const ApplicationRoundGroup: React.FC<ApplicationRoundGroupProps> = ({
  rounds,
}) => {
  return (
    <div className="gap-5 sm:gap-7 application-round-group">
      {rounds.map((item, index) => (
        <div className="flex flex-col justify-center items-center">
          <span className="text-center date-label">
            {convertDateFormat(item.startAt)} - {convertDateFormat(item.endAt)}
          </span>
          <div className={`label-tag-container-${(index % 3) + 1}`}>
            <div className="bubble-wrap">
              <img src="/static/imgs/memReg/doBong1.svg" className="bubble" />
            </div>
            <span className="label">Vòng {item.name}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

interface ApplicationRoundGroupProps {
  rounds: ApplicationRounds[]
}

interface ApplicationRounds {
  name: string
  startAt: string
  endAt: string
}

const TagGroup: React.FC<TagGroupProps> = ({ tags, position }) => {
  const titlePrefix = position === 'tech' ? 'Tech - ' : ''
  const iconName = position === 'tech' ? 'saoDo.svg' : 'voOc.svg'

  return (
    <div className="flex flex-wrap items-center justify-center gap-2 md:gap-6">
      {tags.map((tag, _) => (
        <div
          className={`icon-label-tag-container space-x-1 md:space-x-2 ${position === 'tech' ? 'tech-tag' : 'non-tech-tag'}`}
        >
          <img
            src={`/static/imgs/memReg/${iconName}`}
            width={20.7}
            height={20.7}
          />
          <span className="label">{titlePrefix + tag}</span>
        </div>
      ))}
    </div>
  )
}

interface TagGroupProps {
  tags: string[]
  position: 'tech' | 'non-tech'
}
