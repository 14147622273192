import axios from 'axios'
import queryString from 'query-string'

// Set up default config for HTTP requests here
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    // Return the response data if the response is successful
    if (response && response.data) {
      return response.data
    }
    return response
  },
  (error) => {
    // If the response has an error, still return a structured error response
    if (error.response) {
      // Return the error response if it exists
      return Promise.resolve({
        status: error.response.data?.status,
        message: error.message,
        data: error.response.data,
      })
    }

    // If no response, return the error message as a rejected promise
    return Promise.resolve({
      status: null,
      message: error.message,
      data: null,
    })
  },
)

export default axiosClient
