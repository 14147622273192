import { memo } from 'react'
import './styles.scss'
import { Layout1 } from './components/Layout1'
import MemberRegSe from './components/MemberRegSe'

const MemberReg = () => {
  return (
    <div className="w-[100%]">
        <Layout1/>
        <MemberRegSe></MemberRegSe>
    </div>
  )
}


export default memo(MemberReg)
