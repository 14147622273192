import { memo } from 'react'
import Layout1 from './Layout1'
import Layout2 from './Layout2'

const AboutUs = () => {
  return (
    <div>
      <Layout1 />
      <Layout2 />
    </div>
  )
}
export default memo(AboutUs)
