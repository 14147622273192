import { ArrowRightOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'
import { MenuIcon } from 'common/icon'
import { memo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/const'

const NavForm = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  return (
    <nav className="navbar m-auto xl:max-w-[1540px] fixed top-0 right-0 left-0 h-[80px]  border-b-[1px] border-solid border-[#90CAF9] bg-white z-[1000]">
      <div className="relative flex sm:flex-row md:justify-between justify-center items-center h-[100%] mx-[10px] sm:mx-[20px] xl:mx-[50px]">
        <div
          onClick={showDrawer}
          className="absolute right-0 text-center md:static block sm:w-[180px] lg:hidden"
        >
          <div className="mr-3">
            <MenuIcon width={24} height={24} color="#717171" />
          </div>
        </div>
        <div
          onClick={() => {
            navigate(ROUTES.HOME)
          }}
        >
          <img
            src="/static/imgs/GDSC-PTIT-02@3x 1.svg"
            className="cursor-pointer"
          />
        </div>

        <Button
          icon={<ArrowRightOutlined size={30} color="#498AF4" />}
          iconPosition="end"
          type="primary"
          size="large"
          href={ROUTES.HOME}
          className="nav-btn cursor-pointer hidden md:inline-flex hover:opacity-[0.7]"
        >
          Quay lại trang chủ
        </Button>

        <Drawer
          onClose={onClose}
          open={open}
          placement="left"
          width={200}
          className="nav-drawer md:!w-[300px]"
        >
          <Link to={ROUTES.HOME}>
            {' '}
            <p className="nav-draw-text cursor-pointer">Quay lại trang chủ</p>
          </Link>
        </Drawer>
      </div>
    </nav>
  )
}
export default memo(NavForm)
