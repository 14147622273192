import { memo } from 'react'
import { Button, Modal } from 'antd'
import './styles.scss'
import { ROUTES } from '../../routes/const'
import { CloseOutlined } from '@ant-design/icons'

const ModelReg = ({
  isOpenModal,
  onCancelModal,
}: {
  isOpenModal: boolean
  onCancelModal: () => void
}) => {
  return (
    <Modal open={isOpenModal} className="form__modal">
      <div className="container relative lg:w-[1032px] lg:h-[499px] overflow-hidden">
        <img
          src="/static/imgs/slider-back3.svg"
          alt="oops"
          className="absolute hidden left-[10px] top-[10px] rotate-[50deg] w-[147px] h-[275px] lg:flex"
        />
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="oops"
          className="absolute hidden left-[800px] top-[80px] w-[50px] h-[50px] lg:flex"
        />
        <img
          src="/static/imgs/intro/bongNho.svg"
          alt="oops"
          className="absolute hidden left-[80px] top-[300px] w-[20px] h-[20px] lg:flex"
        />
        <img
          src="/static/imgs/logo.svg"
          alt="oops"
          className="absolute hidden top-[330px] left-[60px] lg:flex"
        />
        <img
          src="/static/imgs/redstar.svg"
          alt="oops"
          className="absolute hidden left-[680px] top-[300px] h-[40px]  z-[1] lg:flex"
        />
        <img
          src="/static/imgs/slider-back4.svg"
          alt="oops"
          className="absolute hidden top-[330px] left-[880px] lg:flex"
        />
        <Button
          icon={<CloseOutlined size={30} color="#498AF4" />}
          type="primary"
          size="large"
          className="btn_close"
          onClick={onCancelModal}
        ></Button>
        <img
          src="/static/imgs/Subtract.svg"
          alt=""
          className="tick w-[55px] h-[55px]"
        />
        <h1 className="title text-[30px] lg:text-[40px] lg:m-[15px]">
          Đăng ký thành công
        </h1>
        <p className="text w-[60%] text-[14px] text-center lg:text-[20px]">
          {' '}
          Cảm ơn bạn đã dành thời gian ứng tuyển vào GDSC-PTIT! 100đ cho sự
          nhiệt tình và tận tâm từ bạn 🙌🏻 Hãy chờ đợi kết quả từ chúng mình nhé!
        </p>
        <Button
          type="primary"
          size="large"
          onClick={() =>
            window.open('https://www.facebook.com/gdsc.ptit', '_blank')
          }
          className="btn btn:hover sm:w-[70%] lg:w-[467px] h-[67px] mt-[20px]"
        >
          Tìm hiểu thêm
        </Button>
      </div>
    </Modal>
  )
}

export default memo(ModelReg)
