import { memo, useState, useEffect } from 'react'
import Footer from 'modules/footer'

import './styles.scss'
import NavForm from './components/NavForm'
import { DEADLINE_TIME, FORM_TYPE, PAGINATION_ITEM } from './const'
import { Controller, useForm } from 'react-hook-form'
import { Button, DatePicker, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import ModelReg from 'components/ModelReg'
import { Form } from 'api'
import { ErrorToast } from 'components/Toast'
import { useNavigate } from 'react-router'
import { ROUTES } from 'routes/const'

const { TextArea } = Input

const FormRegister = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm()

  const [formItem, setFormItem] = useState(FORM_TYPE.slice(0, PAGINATION_ITEM))
  const [isNext, setIsNext] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isPtit, setIsPtit] = useState(true) // Thay đổi state này dựa trên lựa chọn "Trường đang theo học"
  const [isPastDeadline, setIsPastDeadline] = useState(false) // State to track deadline
  const navigate = useNavigate()

  // Check if current date is past the deadline
  useEffect(() => {
    const deadline = new Date(DEADLINE_TIME) // Deadline: 0h18/09/2024
    const now = new Date()

    setIsPastDeadline(now > deadline) // Update state based on the deadline
  }, [])

  const onPaginationNext = async () => {
    const isValid = await trigger(formItem.map((item) => item.name))

    if (isValid) {
      setFormItem(FORM_TYPE.slice(PAGINATION_ITEM, FORM_TYPE.length))
      setIsNext(!isNext)
    } else {
      ErrorToast({
        message: '',
        description: 'Kiểm tra định dạng các trường bắt buộc!',
      })
    }
  }

  const onPaginationPrev = () => {
    formItem.map((item: any) => {
      setValue(item?.name, watch(item?.name))
    })
    setFormItem(FORM_TYPE.slice(0, PAGINATION_ITEM))
    setIsNext(!isNext)
  }

  const onSubmit = async (data: any) => {
    let transformedData = Object.keys(data).reduce((acc: any, key) => {
      const field = FORM_TYPE.find((item) => item.name === key)

      if (data[key]) {
        if (
          field?.type === 'select' &&
          typeof data[key] === 'object' &&
          data[key] !== null
        ) {
          acc[key] = data[key].value
        } else if (field?.type === 'datepicker' && data[key]) {
          acc[key] = dayjs(data[key]).format('YYYY-MM-DD')
        } else {
          acc[key] = data[key]
        }
      }

      return acc
    }, {})
    if (!isPtit) {
      transformedData = { ...transformedData, major: '', student_id: '' }
    }

    const res = await Form.postForm(transformedData)

    if (res?.status) {
      setIsOpenModal(true)
    } else {
      ErrorToast({
        message: '',
        description: res?.data?.message || 'Đã có lỗi xảy ra!',
      })
      navigate('not-found')
    }
  }

  const updatedFormItems = formItem.filter((item: any) => {
    if (item.isPtit) {
      return isPtit
    }
    return true
  })

  return (
    <div className="form max-w-[1560px] m-auto">
      <NavForm />
      <div className="flex flex-col items-center justify-center px-[20px] md:px-[0]">
        <h1 className="form__title mt-[100px] mb-[30px] text-[48px]">
          Đơn đăng ký
        </h1>

        {isPastDeadline ? (
          <div className="form__dealine-text text-center text-[24px] text-[#ff0000]">
            Đã kết thúc vòng đơn, cảm ơn mọi người!
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-[100%] md:w-[50%] flex flex-col items-center justify-center"
          >
            {updatedFormItems.map((item: any) => (
              <Controller
                key={item?.id}
                name={item?.name}
                control={control}
                // defaultValue={
                //   item?.type === 'select'
                //     ? item?.value[0]
                //     : item?.type === 'datepicker'
                //       ? dayjs()
                //       : ''
                // }
                rules={{
                  required: item.require
                    ? 'Trường này là bắt buộc nhập.'
                    : false,
                  pattern: item.rule
                    ? { value: item.rule.pattern, message: item.rule.message }
                    : undefined,
                }}
                render={({ field }) => (
                  <div className="flex flex-col items-start w-[100%] mt-4">
                    <div
                      className={`form__item-title text-[16px] lg:text-[18px] xl:text-[20px] mb-[10px] ${item?.isPtit ? (isPtit ? 'block' : 'hidden') : 'block'}`}
                    >
                      {item?.question}
                      {item?.require && (
                        <span className="text-[#ff0000] ml-2">*</span>
                      )}
                    </div>
                    <div className="flex flex-col w-[100%]">
                      {item?.type === 'input' && (
                        <Input
                          className={`${item?.isPtit ? (isPtit ? 'block' : 'hidden') : 'block'} form__item-input w-[100%] !h-[55px]`}
                          {...field}
                          status={errors[item.name] ? 'error' : ''}
                          onBlur={() => trigger(item.name)}
                        />
                      )}
                      {item?.type === 'select' && (
                        <Select
                          className={`${item?.isPtit ? (isPtit ? 'block' : 'hidden') : 'block'} form__item-select w-[100%] !h-[55px]`}
                          {...field}
                          status={errors[item.name] ? 'error' : ''}
                          options={item?.value}
                          onBlur={() => trigger(item.name)}
                          // value={field.value}
                          onChange={(value) => {
                            field.onChange(value)
                            if (item?.name === 'is_ptit') {
                              setIsPtit(value === '1')
                            }
                          }}
                        />
                      )}
                      {item?.type === 'textArea' && (
                        <TextArea
                          className="form__item-input w-[100%]"
                          {...field}
                          status={errors[item.name] ? 'error' : ''}
                          onBlur={() => trigger(item.name)}
                          rows={4}
                        />
                      )}
                      {item?.type === 'datepicker' && (
                        <DatePicker
                          className="form__item-input w-[100%] !h-[55px]"
                          {...field}
                          status={errors[item.name] ? 'error' : ''}
                          format="YYYY-MM-DD"
                          onBlur={() => trigger(item.name)}
                          // defaultValue={dayjs().format('YYYY-MM-DD')}
                        />
                      )}
                      {errors[item.name] && (
                        <div className="text-[#ff0000] mt-2 text-sm">
                          {`${errors[item.name]?.message}`}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              />
            ))}
            <div className="flex justify-center gap-6 mt-8">
              {isNext && (
                <Button
                  type="primary"
                  className="form__btn-submit"
                  onClick={onPaginationNext}
                  icon={<ArrowRightOutlined size={30} color="#498AF4" />}
                  iconPosition="end"
                  size="large"
                  disabled={isPastDeadline} // Disable if past deadline
                >
                  Tiếp
                </Button>
              )}
              {!isNext && (
                <>
                  <Button
                    type="primary"
                    className="form__btn-submit"
                    onClick={onPaginationPrev}
                    icon={<ArrowLeftOutlined size={30} color="#498AF4" />}
                    iconPosition="start"
                    size="large"
                    disabled={isPastDeadline} // Disable if past deadline
                  >
                    Trước
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="form__btn-submit"
                    size="large"
                    disabled={isPastDeadline} // Disable if past deadline
                  >
                    Gửi
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </div>
      <ModelReg
        isOpenModal={isOpenModal}
        onCancelModal={() => {
          setIsOpenModal(false)
          navigate(ROUTES.HOME)
        }}
      />
      <Footer />
    </div>
  )
}

export default memo(FormRegister)
