import { BASE_URL } from 'common/const'
import axiosClient from 'config/axiosClient'
import { API_ROUTES } from './const'

export const Form = {
  postForm: (form_data: any) => {
    const url = `${BASE_URL}${API_ROUTES.create_form}`
    return axiosClient.post(url, form_data)
  },
}
