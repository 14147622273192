import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ROUTES } from './const'
import Home from 'modules/home'
import Form_Register from 'modules/form_register'
import Not_found from 'modules/not_found'

const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.MEMBER_REG,
    element: <Form_Register />,
  },
  {
    path: '*',
    element: <Not_found />,
  },
])

const Routes = () => {
  return <RouterProvider router={router} />
}
export default Routes
