import React from 'react'
import logo from './logo.svg'
import './App.scss'
import Routes from 'routes'
import { Helmet } from 'react-helmet'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

function App() {
  return (
    <>
      <Helmet>
        {/* <meta charSet="utf-8" /> */}
        <title>GDSC-PTIT</title>
      </Helmet>
      <head></head>
      <Routes />
    </>
  )
}

export default App
