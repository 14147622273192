const images = ['DevFest23', 'HNG01748', 'Google TechnologiesKickstart']

const googleText = [
  {
    text: 'G',
    color: '#4285F4',
  },
  {
    text: 'o',
    color: '#EA4335',
  },
  {
    text: 'o',
    color: '#F9AB00',
  },
  {
    text: 'g',
    color: '#4285F4',
  },
  {
    text: 'l',
    color: '#34A853',
  },
  {
    text: 'e',
    color: '#EA4335',
  },
]

export { images, googleText }
