import { ArrowRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { memo } from 'react'
import { ROUTES } from 'routes/const'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './styles.scss'
import { images, googleText } from './const'

const Intro = () => {
  return (
    <div className="relative px-[0] xl:px-[80px]">
      <img
        className="hidden xl:block absolute left-0 top-[40%]"
        src="static/imgs/intro/bongTo.svg"
        alt="opps"
      />
      <img
        className="hidden lg:block absolute right-[20px] top-[20%]"
        src="static/imgs/intro/bongNho.svg"
        alt="opps"
      />
      <img
        className="hidden lg:block absolute right-[50%] top-[10%]"
        src="static/imgs/intro/bongNho.svg"
        alt="opps"
      />
      <div className=" h-[100%] grid grid-rows-2 md:grid-cols-2 md:grid-rows-1 z-[1] pt-[10px] sm:pt-[30px]">
        <div className="intro relative flex flex-col items-center md:items-start md:col-span-1 mt-[20px]">
          <div className="flex flex-col items-center md:block">
            <div className="intro-title mb-[20px] sm:mb-0 px-[8px] bg-[#4285F4] w-fit text-[#fff]">
              <p className="outline-none border-none text-[35px] sm:text-[30px] md:text-[45px] lg:text-[50px] xl:text-[65px]">
                Together
              </p>
            </div>
            <div className="intro-text flex flex-col items-center md:items-start mb-[10px] sm:mb-[0] text-[25px] sm:text-[25px] md:text-[35px] lg:text-[45px] xl:text-[55px]">
              <div className="flex flex-row mb-[10px] sm:mb-0">
                <p className="text-[#000] mr-[8px] md:mr-[15px]">We</p>
                <p className="text-[#4285F4] falling">Learn</p>
                <p className="mr-[8px] md:mr-[15px]">,</p>
                <p className="text-[#EA4335] falling">Share</p>
                <p className="mr-[8px] md:mr-[15px]">,</p>
              </div>
              <div className="flex flex-row">
                <p className="text-[#34A853] falling mr-[8px] md:mr-[15px]">
                  Connect
                </p>
                <p className="mr-[8px] md:mr-[15px]">and</p>
                <p className="text-[#F9AB00] falling">Grow</p>
                <p>.</p>
              </div>
            </div>
          </div>

          <div className="intro-google-text text-[16px] md:text-[16px] lg:text-[24px] flex flex-row gap-[5px] py-[5px] sm:py-[10px] my-[5px] sm:my-[20px] lg:my-[30px]">
            <p className="text-[#394452]">Powered by</p>
            <div className="flex flex-row">
              {googleText.map((item: any, index: number) => (
                <p
                  key={index}
                  className={`intro-google-text-item text-[${item?.color}]`}
                >
                  {item?.text}
                </p>
              ))}
            </div>
            <p className="text-[#5F6368]">for Developers</p>
          </div>
          <div className="flex gap-[10px]">
            <Button
              iconPosition="end"
              type="primary"
              size="large"
              className="intro-btn cursor-pointer"
              onClick={() =>
                window.open('https://www.facebook.com/gdsc.ptit', '_blank')
              }
            >
              Tìm hiểu thêm
            </Button>
            <Button
              icon={<ArrowRightOutlined size={30} color="#498AF4" />}
              iconPosition="end"
              type="primary"
              size="large"
              href={ROUTES.MEMBER_REG}
              className="intro-btn btn-2 cursor-pointer "
            >
              Tham gia CLB
            </Button>
          </div>
        </div>
        <div className="swiper-container md:col-span-1 row-span-1 pt-[30px]">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper w-[335px] sm:w-[100%] h-[100%]"
          >
            <img
              className="intro-slider-back1 w-[80px] md:w-[120px] lg:w-[150px] xl:w-fit"
              src="static/imgs/intro/smallTree.svg"
              alt="opps"
            />
            <img
              className="intro-slider-back2 w-[60px] md:w-[80px] lg:w-[100px] xl:w-fit"
              src="static/imgs/intro/bigTree.svg"
              alt="opps"
            />
            {images.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="image-wrapper">
                  <img
                    src={`/static/imgs/intro/${item}.jpeg`}
                    alt={`slider_image_${index}`}
                    className="max-w-[300px]  sm:max-w-[320px] lg:max-w-[400px] xl:max-w-[500px]"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
export default memo(Intro)
