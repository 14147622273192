import React, { useRef } from 'react'
import './styles.scss'

import { memo } from 'react'
import './styles.scss'
import { IconButton } from 'common/icon'
import useOnScreen from 'hooks/useOnScreen'

import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { SLIDER_IMG } from './const'

type data = {
  text: String
}

const Layout2 = () => {
  const layoutRef = useRef<HTMLDivElement | null>(null)
  const isVisible = useOnScreen(layoutRef)
  // <ImgName text="Google Technologies Kickstart"></ImgName>

  return (
    <div ref={layoutRef} className="relative mt-[120px] ml-[0]">
      <div className=" container relative m-auto ">
        <img src="static/imgs/aboutUs/bigGdsc.svg" className="GDSC" />
        <div className="Title">
          <div className="Title-Icon"></div>
          <img src="static/imgs/aboutUs/voSo.svg " />
          <h3>Hoạt động nổi bật</h3>
        </div>
        <div className="Content mt-[50px]">
          <img src="static/imgs/aboutUs/bong.svg" className="BongBong1" />
          <img src="static/imgs/aboutUs/bong.svg" className="BongBong2" />
          <img src="static/imgs/aboutUs/cayTrai.svg" className="cayTrai" />
          <img src="static/imgs/aboutUs/cayPhai.svg" className="cayPhai" />
          {SLIDER_IMG.map((item: any, index: number) => (
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              loop={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="Content-Img max-w-[300px] sm:max-w-[450px] lg:max-w-[450px] xl:max-w-[500px]"
            >
              {item?.data?.map((img: any) => (
                <SwiperSlide key={index}>
                  <div>
                    <img
                      src={img}
                      alt={`slider_image_${index}`}
                      className="max-w-[300px] sm:max-w-[450px] lg:max-w-[450px] xl:max-w-[500px]"
                    />
                  </div>
                </SwiperSlide>
              ))}
              <p className="Content-Img-text">{item?.title}</p>
            </Swiper>
          ))}
        </div>
      </div>
      <img src="static/imgs/wave.svg" className="wave" />
      <img src="static/imgs/duongKe.svg" className="duong_ke" />
    </div>
  )
}
export default memo(Layout2)
