import React, { useRef, useEffect, useState } from 'react'
import { Carousel } from 'antd'
import { ArrowLeft, ArrowRight } from 'common/icon'
import './style.scss'
import { ROUTES } from '../../../../../../routes/const'
import { useNavigate } from 'react-router'

const quotes = [
  {
    avatar: '/static/imgs/memberReg/pham_dang_khoa.png',
    author: 'Phạm Đăng Khoa',
    team: 'Des',
    content:
      "Sau 1 năm chinh chiến cùng team des, vui có, buồn có, nhưng trên hết, chúng mình được 'bung lụa' hết mình với những ý tưởng. dù có khi bị deadline 'kìm hãm', nhưng những lúc như vậy, lại có các anh chị, bạn bè nhiệt tình dẫn dắt, giúp đỡ, giúp mình học hỏi được rất nhiều. Mãi yêu team des, mãi yêu gdsc 😘",
  },
  {
    avatar: '/static/imgs/memberReg/hoang_cao_minh_ha.jpg',
    author: 'Hoàng Cao Minh Hà',
    team: 'HR - LG',
    content:
      'Lúc vào Nhân sự Hậu cần thì mình hơi bất ngờ xíu vì team toàn con trai mỗi mình là con gái =))) nhưng mà mọi người thân thiện và support nhau rất nhiều nên tự dưng mình cũng hòa tan theo. Mọi người trong team kiểu bảo vệ nhau lắm những lúc có làm sai gì thì mọi người vẫn bình tĩnh để tìm ra hướng giải quyết rui mới nhắc nhở lỗi sai sau siêu iêu trừi ui 🫶🫶',
  },
  {
    avatar: '/static/imgs/memberReg/truong_minh_danh.jpg',
    author: 'Trương Minh Danh',
    team: 'Tech Gen 2',
    content:
      'Đồng hành cùng GDSC chỉ mới 1 năm thôi nhưng GDSC đã cho mình rất nhiều, giúp mình không chỉ có thêm kiến thức, kĩ năng chuyên môn mà bên cạnh đó mình có cơ hội được networking và làm việc cùng với những người anh, chị đi trước, chính điều đó đã giúp mình thay đổi cái mindset của mình trước đây rất nhiều.',
  },
  {
    avatar: '/static/imgs/memberReg/le_quoc_bao.jpg',
    author: 'Lê Quốc Bảo',
    team: 'Tech Gen 2',
    content:
      'Lúc đầu mình nghĩ GDSC là một cộng đồng lớn và chuyên nghiệp nên sẽ không dành cho những đứa newbie như mình mới tập tành code.Nhưng sau các buổi training của câu lạc bộ thì chính những buổi sinh hoạt của GDSC luôn là thời gian mình mong đợi nhất. Được ngồi lại với các anh chị, chia sẻ kiến thức, cùng nhau làm project và có cả những buổi trò chuyện vui vẻ về mọi thứ, từ công nghệ đến tâm sự tuổi hồng về thanh xuân vườn trường!',
  },
  {
    avatar: '/static/imgs/memberReg/nguyen_thuy_toan.jpg',
    author: 'Nguyễn Thị Thúy Toàn',
    team: 'Tech Gen 2',
    content:
      'Team tech nơi vào để khiến chúng ta peer pressure lẫn nhau nhé, dù là những búp măng non nhưng sau 1 năm t thấy quá nhiều sự thay đổi, các bạn cùng nhau học tập, chia sẻ và sẽ khô khan ư. Không hề luôn, vào đây để được lập hội cháy và siêu vui cùng các buổi chạy sự kiện nhé. Tôi rất mê cái sự đoàn kết này của GDSC đó ạ😉. Túm cái váy lại thì vô GDSC để hiểu hơn cảm giác“giật mình biết tin”, để định nghĩa GDSC thì đó là “nhà”!',
  },
  {
    avatar: '/static/imgs/memberReg/nguyen_duc_thanh.jpg',
    author: 'Nguyễn Đức Thành',
    team: 'Tech Gen 2',
    content:
      'Mình đã nghĩ năm nhất của mình sẽ thật nhàm chán cho tới khi vào GDSC. Vào GDSC mình đã cảm nhận được sự gần gũi chưa từng có giữa các thành viên. Ở GDSC PTIT mọi người đều rất giỏi điều đó khiến mình không ngừng cố gắng để hoàn thiện khi ở đây.',
  },
]

function MemberRegSe() {
  const navigate = useNavigate()

  const carouselRef = useRef<any>(null)

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev()
    }
  }

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next()
    }
  }
  const carouselSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <div className="mb-28 px-2 lg:px-20 mt-36">
      <div className="relative mb-2 md:mb-10">
        <img
          className="absolute w-8 lg:w-auto left-[-30px] top-[-30px] lg:top-[-98px] lg:left-[-96px]"
          src="/static/imgs/memberReg/bong.svg"
        ></img>
        <img
          className="w-24 md:w-28 lg:w-auto absolute top-[-63px] right-[-26px]"
          src="/static/imgs/memberReg/caDo.svg"
        ></img>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-lightBlue px-4 lg:px-20 md:h-[380px] lg:h-[588px] rounded-3xl shadow-xl lg:mx-4">
          <div className="col-span-1">
            <div className="grid grid-cols-2 gap-4 md:gap-8">
              <div className="col-span-1 flex flex-col gap-6">
                <div className="max-w-60 h-40 md:h-48 lg:h-72 rounded-3xl bg-blue px-2 py-4  lg:px-7 lg:py-14">
                  <div className="flex">
                    <span className="text-white text-xl md:text-3xl lg:text-4xl sans_semibold-text">
                      1
                    </span>
                    <div className="w-2 h-2 rounded-full bg-yellow"></div>
                  </div>
                  <p className="text-white text-lg md:text-xl lg:text-2xl p-2 md:p-4 md:pt-2 sans_regular-text">
                    Nâng cao kiến thức và kỹ năng
                  </p>
                </div>
                <div className="max-w-60 h-40 md:h-48 lg:h-72 rounded-3xl bg-white px-2 py-4  lg:px-7 lg:py-14">
                  <div className="flex">
                    <span className="text-blue text-xl md:text-3xl lg:text-4xl sans_semibold-text">
                      2
                    </span>
                    <div className="w-2 h-2 rounded-full bg-yellow"></div>
                  </div>
                  <p className="text-blue text-lg md:text-xl lg:text-2xl p-2 md:p-4 md:pt-2 sans_regular-text">
                    Mở rộng mối quan hệ
                  </p>
                </div>
              </div>
              <div className="col-span-1 flex flex-col gap-6">
                <div className="mt-10 max-w-60 h-40 md:h-48 lg:h-72 rounded-3xl bg-white px-2 py-4 lg:px-7 lg:py-14">
                  <div className="flex">
                    <span className="text-blue text-xl md:text-3xl lg:text-4xl sans_semibold-text">
                      3
                    </span>
                    <div className="w-2 h-2 rounded-full bg-yellow"></div>
                  </div>
                  <p className="text-blue text-lg md:text-xl lg:text-2xl p-2 md:p-4 md:pt-2 sans_regular-text">
                    Phát triển bản thân
                  </p>
                </div>
                <div className="max-w-60 h-40 md:h-48 lg:h-72 rounded-3xl bg-blue px-2 py-4 lg:px-7 lg:py-14">
                  <div className="flex">
                    <span className="text-white text-xl md:text-3xl lg:text-4xl sans_semibold-text">
                      4
                    </span>
                    <div className="w-2 h-2 rounded-full bg-yellow"></div>
                  </div>
                  <p className="text-white text-lg md:text-xl lg:text-2xl p-2 md:p-4 md:pt-2 sans_regular-text">
                    Trải nghiệm đáng nhớ
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 flex flex-col justify-center my-9 items-center md:h-[320px] lg:h-[550px]">
            <h2 className="text-xl md:text-2xl lg:text-4xl text-center mb-4 md:mb-6 sans_bold-text">
              Tại sao bạn nên tham gia
            </h2>
            <button
              onClick={() => navigate(ROUTES.MEMBER_REG)}
              className="px-7 py-2 lg:px-14 lg:py-3 bg-red text-white rounded-full text-lg md:text-[20px] lg:text-[28px] w-fit sans_medium-text"
            >
              Đăng ký ngay
            </button>
          </div>
        </div>
      </div>

      <div className=" gap-4 sm:gap-10 bg-lightBlue px-4 py-4 lg:px-20 lg:pt-[50px] rounded-3xl shadow-xl lg:mx-4 mt-[100px]">
        <Carousel ref={carouselRef} {...carouselSettings}>
          {quotes.map((quote, index) => (
            <div key={index} className="!grid grid-cols-3">
              <div className="col-span-3 md:col-span-1 flex items-center justify-center mr-[20px]">
                <img
                  className="max-h-[250px] lg:max-w-[300px] lg:max-h-[300px] md:w-full rounded-full"
                  src={quote.avatar}
                  alt="Avatar"
                />
              </div>
              <div className="col-span-3 md:col-span-2 flex flex-col justify-center">
                <p className="text-blue text-[22px] lg:text-[34px] text-center md:text-start sans_bold-text">
                  {quote.author}
                </p>
                <p className="text-blue text-[18px] lg:text-[26px] text-center md:text-start sans_medium-text">
                  {quote.team}
                </p>
                <p className="text-[1rem] lg:text-2xl font-normal mt-4 md:mt-[20px] sans_medium-text">
                  {quote.content}
                </p>
                <div
                  className={` flex gap-6 mt-3 md:mt-4 lg:mt-10 justify-center md:justify-start `}
                >
                  <button onClick={handlePrev}>
                    <ArrowLeft className="w-6 md:w-8" color={''}></ArrowLeft>
                  </button>
                  <button onClick={handleNext}>
                    <ArrowRight className="w-6 md:w-8" color={''}></ArrowRight>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default MemberRegSe
