export const PAGINATION_ITEM = 11

export const DEADLINE_TIME = '2024-09-19T00:00:00'

export const FORM_TYPE = [
  {
    id: 1,
    question: 'Họ và tên:',
    name: 'name',
    type: 'input',
    value: '',
    require: true,
  },
  {
    id: 2,
    question: 'Giới tính:',
    name: 'sex',
    type: 'select',
    value: [
      { label: 'Nam', value: '1' },
      { label: 'Nữ', value: '0' },
    ],
    require: true,
  },
  {
    id: 3,
    question: 'Ngày tháng năm sinh:',
    name: 'date_of_birth',
    type: 'datepicker',
    value: '',
    require: true,
  },
  {
    id: 4,
    question: 'Email:',
    name: 'email',
    type: 'input',
    value: '',
    require: true,
    rule: {
      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      message: 'Email phải hợp lệ và bắt đầu bằng ký tự @.',
    },
  },
  {
    id: 5,
    question: 'Số điện thoại:',
    name: 'phone_number',
    type: 'input',
    value: '',
    rule: {
      pattern: /^0\d{9}$/,
      message: 'Số điện thoại phải bắt đầu bằng số 0 và có đúng 10 ký tự.',
    },
    require: true,
  },
  {
    id: 6,
    question: 'Link facebook:',
    name: 'social_link',
    type: 'input',
    value: '',
    rule: {
      pattern: /^https:\/\/www\.facebook\.com\/.+$/,
      message: 'Link Facebook phải bắt đầu bằng "https://www.facebook.com/".',
    },
  },
  {
    id: 7,
    question: 'Trường đang theo học:',
    name: 'is_ptit',
    type: 'select',
    value: [
      {
        label: 'Học viện Công nghệ Bưu chính Viễn thông',
        value: '1',
      },
      { label: 'Khác', value: '0' },
    ],
    require: true,
  },
  {
    id: 8,
    question: 'Mã sinh viên:',
    name: 'student_id',
    type: 'input',
    value: '',
    require: true,
    isPtit: true,
  },
  {
    id: 9,
    question: 'Chuyên ngành:',
    name: 'major',
    type: 'select',
    value: [
      {
        label: 'Công nghệ thông tin',
        value: 'Công nghệ thông tin',
      },
      {
        label: 'Kỹ thuật điện tử viễn thông',
        value: 'Kỹ thuật điện tử viễn thông',
      },
      {
        label: 'Công nghệ kỹ thuật điện, điện tử',
        value: 'Công nghệ kỹ thuật điện, điện tử',
      },
      {
        label: 'Kỹ thuật điều khiển và tự động hóa',
        value: 'Kỹ thuật điều khiển và tự động hóa',
      },
      {
        label: 'An toàn thông tin',
        value: 'An toàn thông tin',
      },
      {
        label: 'Khoa học máy tính',
        value: 'Khoa học máy tính',
      },
      {
        label: 'Kỹ thuật dữ liệu',
        value: 'Kỹ thuật dữ liệu',
      },
      {
        label: 'Công nghệ thông tin Việt Nhật',
        value: 'Công nghệ thông tin Việt Nhật',
      },
      {
        label: 'Công nghệ thông tin (cử nhân)',
        value: 'Công nghệ thông tin (cử nhân)',
      },
      {
        label: 'Báo chí',
        value: 'Báo chí',
      },
      {
        label: 'Quản trị kinh doanh',
        value: 'Quản trị kinh doanh',
      },
      {
        label: 'Thương mại điện tử',
        value: 'Thương mại điện tử',
      },
      {
        label: 'Marketing',
        value: 'Marketing',
      },
      {
        label: 'Kế toán',
        value: 'Kế toán',
      },
      {
        label: 'Công nghệ tài chính',
        value: 'Công nghệ tài chính',
      },
      {
        label: 'Thiết kế và phát triển Game',
        value: 'Thiết kế và phát triển Game',
      },
      {
        label: 'Công nghệ thông tin CLC',
        value: 'Công nghệ thông tin CLC',
      },
      {
        label: 'Kế toán CLC',
        value: 'Kế toán CLC',
      },
      {
        label: 'Công nghệ đa phương tiện',
        value: 'Công nghệ đa phương tiện',
      },
      {
        label: 'Truyền thông đa phương tiện',
        value: 'Truyền thông đa phương tiện',
      },
      {
        label: 'Marketing CLC',
        value: 'Marketing CLC',
      },
      {
        label: 'Quan hệ công chúng',
        value: 'Quan hệ công chúng',
      },
      { label: 'Khác', value: 'Khác' },
    ],
    require: true,
    isPtit: true,
  },
  {
    id: 10,
    question: 'Bạn là sinh viên năm:',
    name: 'year_student',
    type: 'select',
    value: [
      {
        label: 'Năm 1',
        value: '1',
      },
      { label: 'Năm 2', value: '2' },
      { label: 'Năm 3', value: '3' },
      { label: 'Năm 4', value: '4' },
    ],
    require: true,
  },
  {
    id: 11,
    question: 'Ban ứng tuyển:',
    name: 'team',
    type: 'select',
    value: [
      {
        label: 'Tech',
        value: '1',
      },
      {
        label: 'Des',
        value: '6',
      },
      {
        label: 'HR-LG',
        value: '2',
      },
      {
        label: 'PR',
        value: '3',
      },
      {
        label: 'Media',
        value: '5',
      },
    ],
    require: true,
  },
  {
    id: 12,
    question: 'Giới thiệu tổng quan về bản thân:',
    name: 'intro_yourself',
    type: 'textArea',
    value: '',
    require: true,
  },
  {
    id: 13,
    question: 'Lý do bạn chọn GDSC - PTIT:',
    name: 'reason',
    type: 'textArea',
    value: '',
    require: true,
  },
  {
    id: 14,
    question: 'Kinh nghiệm kiến thức về ban ứng tuyển:',
    name: 'experience',
    type: 'textArea',
    value: '',
  },
  {
    id: 15,
    question: 'Kinh nghiệm khác (nếu có):',
    name: 'experience_other',
    type: 'textArea',
    value: '',
  },
  {
    id: 16,
    question: 'Link CV/Linked/Portfolio/Project/Giải thưởng (nếu có)',
    name: 'link_cv',
    type: 'textArea',
    value: '',
  },
  {
    id: 17,
    question: 'Bạn biết đến CLB qua đâu',
    name: 'about_club',
    type: 'select',
    value: [
      {
        label: 'Facebook',
        value: 'Facebook',
      },
      { label: 'Bạn bè', value: 'Bạn bè' },
      { label: 'Điền đơn', value: 'Điền đơn' },
      { label: 'Khác', value: 'Khác' },
    ],
    require: true,
  },
  {
    id: 18,
    question: 'Câu hỏi cho GDSC - PTIT (nếu có)',
    name: 'question',
    type: 'input',
    value: '',
  },
]
